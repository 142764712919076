import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import BackImage from '../../images/BG.svg';
import PhoneFront from '../../images/phone01.png';
import PhoneBack from '../../images/phone02.png';

import Header, { NavLink, NavLinks, PrimaryLink, LogoLink, NavToggle, DesktopNavLinks } from "../headers/light.js";

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none`}
  ${NavLink} {
    ${tw`text-primary_text hover:border-gray-300 hover:text-gray-300`}
  }
  ${DesktopNavLinks} ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;
const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover`}
  /* background-image: url(${BackImage}) */
  /* background-image: url("https://images.unsplash.com/photo-1522071901873-411886a10004?ixlib=rb-1.2.1&auto=format&fit=crop&w=1920&q=80"); */
`;


const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const TwoColumn = tw.div`pt-12 pb-32 px-4 flex justify-between items-center flex-col lg:flex-row`;
const LeftColumn = tw.div`flex flex-col items-center lg:block`;
const RightColumn = tw.div`w-full sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8`;

const Heading = styled.h1`
  ${tw`text-5xl text-center lg:text-left sm:text-6xl lg:text-6xl xl:text-6xl font-black text-gray-100 leading-none`}
  span {
    ${tw`inline-block mt-2 text-default_text`}
  }
`;

const SlantedBackground = styled.span`
  ${tw`relative text-primary-500 px-4 -mx-4 py-2`}
  &::before {
    content: "";
    ${tw`absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10`}
  }
`;

const Notification = tw.span`inline-block text-primary_text font-medium text-sm`;

const HighlightedText = tw.span`text-secondary-100`;
const ColumnContainer = tw.div`lg:w-1/2 max-w-lg`
const Link = tw.a`w-full sm:w-auto text-sm px-6 py-3 sm:px-48 sm:py-4 lg:px-10 lg:py-5 mt-12 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 rounded-full font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const LinksContainer = tw(ColumnContainer)` max-w-none flex mt-48 lg:mt-4 flex-col sm:flex-row`;
const ContainerPrimaryLink = tw(Link)`bg-primary-500 text-gray-100 shadow-lg hocus:bg-secondary-800 hocus:text-gray-200`;
const ContainerSecondaryLink = tw(Link)`text-primary_text hover:bg-gray-100 hover:text-primary-500 hover:border-primary-100`;

const Row = tw.div`px-2 mt-8 max-w-none flex flex-col lg:flex-row text-center lg:text-left`;

export default () => {
  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="#">
        Sobre
      </NavLink>
      {/* <NavLink href="#">
        Blog
      </NavLink> */}
      {/* <NavLink href="#">
        Planos
      </NavLink> */}
      <NavLink href="#">
        Contato
      </NavLink>
    </NavLinks>,
    <NavLinks key={2}>
      <PrimaryLink href="https://wa.me/551151040556?text=Ol%C3%A1%2C%20gostaria%20de%20conhecer%20a%20MedIAssist">
        Comece agora
      </PrimaryLink>
    </NavLinks>
  ];

  var sectionStyle = {
    flexDirection: 'row',
    width: "200%",
    height: "600px",
    marginRight: "174px",
    backgroundsize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${BackImage})`
  };

  var sectionStyle2 = {
    width: "40%",
    height: "800px",
    marginLeft: "372px",
    paddingRight: "172px",
    backgroundsize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${PhoneBack})`
  };

  var sectionStyle3 = {
    width: "40%",
    height: "800px",
    marginTop: "-720px",
    backgroundsize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${PhoneFront})`
  };

  return (
    <Container>
      <HeroContainer>
        <StyledHeader links={navLinks} />

        <TwoColumn>
          <LeftColumn>
            <Notification>Uma ferramenta incrível que vai te ajudar</Notification>
            <br />
            <Notification>no dia-a-dia a cuidar do seu paciente.</Notification>
            <Heading>
              <span>O poder da IA</span>
              <br />
              <span>na sua mão.</span>
              <Row>
                <ContainerPrimaryLink href="https://wa.me/551151040556?text=Ol%C3%A1%2C%20gostaria%20de%20conhecer%20a%20MedIAssist">Comece agora</ContainerPrimaryLink>
                <ContainerSecondaryLink href="http://timerse.com">Fale com nossa equipe</ContainerSecondaryLink>
              </Row>
            </Heading>

          </LeftColumn>
          <RightColumn>
            <section style={sectionStyle}>
              <section style={sectionStyle2}>
              </section>
              <section style={sectionStyle3}>
              </section>
            </section>
          </RightColumn>

        </TwoColumn>
      </HeroContainer>
    </Container >
  );
};
