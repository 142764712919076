import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
// import Hero from "components/hero/TwoColumnWithInput.js";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import Pricing from "components/pricing/TwoPlansWithDurationSwitcher.js";

import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/FiveColumnWithBackground.js";
import heroScreenshotImageSrc from "images/team.jpg";
import Hero from "components/hero/BackgroundAsImage.js";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage>
      <Hero />

      <Features
        subheading={<Subheading></Subheading>}
        heading={
          <>
            O <HighlightedText>MedIAssist</HighlightedText> é um robô assistente que vai te ajudar no cuidado com os seus pacientes
          </>
        }
      />
      <MainFeature
        subheading={<Subheading></Subheading>}
        imageSrc={heroScreenshotImageSrc}
        imageBorder={true}
        imageDecoratorBlob={true}
      />
      <FAQ
        subheading={<Subheading></Subheading>}
        heading={
          <>
            Você tem alguma <HighlightedText>Dúvida ?</HighlightedText>
          </>
        }
        faqs={[
          {
            question: "Como funciona o pagamento?",
            answer:
              "O pagamento é efetuado mensalmente após a realização de um cadastro simples de forma prática e sem complicações."
          },
          {
            question: "Quais os limites de uso ?",
            answer: "Os limites de uso variam conforme o plano selecionado e podem incluir restrições quanto ao número de consultas e funcionalidades disponíveis."
          },
          {
            question: "Quais as fontes de dados que utilizamos ?",
            answer: "Utilizamos exclusivamente fontas de instituições oficiais de saúde, como o Ministério da Saúde, DATASUS, FDA, Medline, entre outras reconhecidas por sua credibilidade."

          }
        ]}
      />
      <GetStarted />
      <Footer />
    </AnimationRevealPage>
  );
}
